import { useServiceContainer } from './utils/ServiceContainerSingleton'
import FS from '@wsi/web-core/services/FederationService'

import _AuthotizazionService from '@rca/vue-core/shell/services/AuthorizationService'
import _LogService from '@rca/vue-core/shell/services/LogService'
import ContentService from '@rca/vue-core/shell/services/ContentService'
import _LoginService from '@rca/vue-core/shell/services/LoginService'
import MenuService from '@rca/vue-core/shell/services/MenuService'
import SearchService from '@rca/vue-core/shell/services/SearchService'
import WhitelabelService from '@rca/vue-core/shell/services/WhitelabelService'
import TrackingService from '@rca/vue-core/shell/services/TrackingService'

const serviceContainer = useServiceContainer()
const LogService = _LogService()
LogService.addWindowListeners()
LogService.setLogLevel('WARN')

let cfg = null
try {
  cfg = await (await fetch('/cfg')).json()
  window._ClientConfig = cfg
} catch (error) {
  LogService.error(error)
}

serviceContainer.setContainerConfig(cfg)

const AuthorizationService = _AuthotizazionService({
  ALLOWED_SCOPES: ['TOOL_BOFI', 'TOOL_BOFI_ADMIN']
})
const LoginService = _LoginService()
const FederationService = new FS(cfg)

serviceContainer.registerServices({
  FederationService,
  AuthorizationService,
  LogService,
  ContentService: ContentService(LogService),
  LoginService,
  MenuService: MenuService({ AuthorizationService, LoginService, cfg }),
  SearchService: SearchService(),
  WhitelabelService: new WhitelabelService(cfg, FederationService),
  TrackingService: new TrackingService(
    { apiUrl: cfg.API_URL, appName: 'RCA_BOFI', ENABLE_TRACKING: cfg.ENABLE_TRACKING },
    {},
    { logger: LogService }
  )
})

import('./bootstrap')
